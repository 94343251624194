@import "../custom.scss";

.card.paymentView{
    border: 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;

    .card-body{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: $black;
    }

    .user-infos{
        img{
            width: 80px;
            height: 80px;
        }

        .user-infos--name{
            font-weight: 600;
        }
    }
    
    .amount-formControl{
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .flat-input-wrapper{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 1rem;
        font-size: 54px;
    
        .form-control{
            border: 0;
            text-align: center;
            padding: 0;
            min-height: auto;
            background-color: transparent;
            color: $black;
            font-size: 54px;
            font-weight: 600;

            &::placeholder{
                color: $gray-400;
            }
        }

        & > span{
            font-weight: 500;
            font-size: 54px;
            line-height: normal;
        }
    
        &:after{
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: $gray-400;
        }
    }
}

.card.paymentResponse{
    height: 100%;
    width: 100%;
    border: 0;
    
    .card-body{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        color: $black;
    }

    .user-infos{
        width: 100%;
        padding-bottom: 1rem;
        border-bottom: 1px solid $gray-200;
        margin-bottom: 1rem;

        img{
            width: 40px;
            height: 40px;
        }

        .user-infos--name{
            font-weight: 500;
        }
    }

    ul{
        width: 100%;
        li{
            margin-bottom: 1rem;
            font-weight: 500;
            display: flex;
            flex-direction: column;

            label{
                font-size: 14px;
                color: $gray-600;
                margin-bottom: 0.25rem;
            }
        }
    }

    .paymentResponse-creditCard{
        padding: 1rem;
        border-radius: 6px;
        background-color: $gray-100;
        display: flex;
        align-items: center;
        justify-content: space-between;

        font-size: 14px;
        font-weight: 500;
        line-height: normal;

        .credit-creditCard--icon{
            width: 50px;
            height: auto;
        }

        .paymentResponse-creditCard--brand{
            font-weight: 600;
            text-transform: capitalize;
        }

        .paymentResponse-creditCard--number{
            color: $gray-600;
            font-size: 13px;
        }
    }
}