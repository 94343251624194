@import "./gilroyfont.scss";
@import "./bootstrap.scss";

html{
  height: 100%;
  overflow: hidden;
}

body, #root{
  height: 100%;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.form-control:focus,
input:focus{
    outline: 0;
    box-shadow: none;
}

.App {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}


.truncate {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}
